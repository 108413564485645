<template>
  <div class="address">
    <center-title title="审批管理"></center-title>
    <router-link to="/addapproval" class="add row-center xs mb20"
      >+ 新增审批</router-link
    >
    <div class="table">
      <div class="table-item th row lighter">
        <div class="item">功能节点</div>
        <div class="item">初审人</div>
        <div class="item">终审人</div>
        <div class="item">操作</div>
      </div>
      <div
        class="table-item row xs"
        v-for="(item, index) in approvalLists"
        :key="index"
      >
        <div class="item">{{ item.approvalTypeText }}</div>
        <div class="item">{{item.earlyApproval}}</div>
        <div class="item">{{ item.finalApproval }}</div>
        <div class="item">
          <router-link
            :to="`/editapproval?&id=${item.id}`"
            class="status-item primary"
            >修改</router-link
          >
        </div>
      </div>
      <div class="table-item row xs" v-if="!approvalLists.length">
        <div class="item flex1">暂无审批配置</div>
      </div>
    </div>
  </div>
</template>
<script>
	import CenterTitle from "../components/CenterTitle";
	import MakePagination from "@/components/MakePagination";
	export default {
		name: "",
		props: {},
		components: {
			"center-title": CenterTitle,
			"make-pagination": MakePagination,
		},
		data() {
			return {
				approvalLists: [],
				page_no: 1,
				page_size: 10,
			};
		},
		computed: {},
		watch: {},
		methods: {
			resetList(e) {
				this.page_no = e;
				this.getApprovalLists();
			},
			/**
			 * @description 获取审批配置列表
			 * @return void
			 */
			getApprovalLists() {
				let {
					page_no,
					page_size
				} = this;
				this.$api
					.getApprovalListsApi({
						page_no,
						page_size
					})
					.then((res) => {
						this.approvalLists = res.data;
					});
			}
		},
		created() {
			this.getApprovalLists();
		},
		mounted() {},
		beforeDestroy() {},
	};
</script>
<style lang='scss' scoped>
.add {
  border: $-solid-border;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.table {
  border-top: $-solid-border;
  border-left: $-solid-border;
  .table-item {
    align-items: inherit;
    &.th {
      background-color: $-color-center;
    }
    .item {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      line-height: 1.5;
      border-right: $-solid-border;
      border-bottom: $-solid-border;
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 25%;
      }
    }
  }
}
</style>